import { useState, useRef, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import VehicleTypeModalOnSellSentry from "@/components/buy/modals/VehicleTypeModalOnSellSentry";
import StateListModal from "@/components/modals/StateListModal";
import { checkNativePage, isMobileDevice, parseBgColor } from "@/libs/utils";
import cn from "classnames";
import {
  PlateVinFormType,
  usePlateVinEntryForm,
} from "@/hooks/usePlateVinEntryForm";
import { useRouter } from "next/router";
import { useAuthValue } from "@/contexts/contextAuth";
import VintageEntryModal from "./VintageEntryModal";

type Props = {
  data: any;
};

const Design2VinEntry = ({ data }: Props) => {
  const btnRef = useRef<HTMLButtonElement>(null);
  const btnRef1 = useRef<HTMLButtonElement>(null);
  const btnRef2 = useRef<HTMLButtonElement>(null);
  const btnRef3 = useRef<HTMLButtonElement>(null);
  const btnRef4 = useRef<HTMLButtonElement>(null);
  const isNative = checkNativePage();
  const {
    form,
    changeType,
    changeState,
    changeVType,
    isVinShort,
    handleSubmit,
    isBadReq,
    showVehicleModal,
    isVehicleModalOnSellEntry,
    setIsVehicleModalOnSellEntry,
    isStateModalOpen,
    showStateModal,
    openVintageVehicleModal,
    setIsOpenVintageVehicleModal,
    handleCreateVintage,
  } = usePlateVinEntryForm();
  const {
    register,
    formState: { errors },
    watch,
  } = form;
  const selectedType = watch("type");
  const vehicleType = watch("vType");
  const selectedState = watch("state");
  const currentVin = watch("vin");
  const isVin = selectedType === PlateVinFormType.Vin;

  const { setIsLoading } = useAuthValue();

  const [isMobileView, setIsMobileView] = useState(false);
  const hasSubmittedRef = useRef(false);

  useEffect(() => {
    if (data.VehicleType == "Car") {
      changeVType("Auto");
    } else {
      changeVType(data.VehicleType);
    }
  }, [data.VehicleType]);
  useEffect(() => {
    if (data.CoBrandedName) {
      form.setValue("coBrandedName", data.CoBrandedName);
    }
  }, [data.CoBrandedName]);
  const router = useRouter();

  useEffect(() => {
    if (router.isReady) {
      const { vin } = router.query;
      const vinValue = Array.isArray(vin) ? vin[0] : vin;

      if (vinValue) {
        setIsLoading(true);
        form.setValue("vin", vinValue);
        form.setValue("state", "pass");
        changeType(PlateVinFormType.Vin);
      }
    }
  }, [router.isReady, router.query, form, changeType]);

  useEffect(() => {
    const vin = form.getValues("vin");
    const type = form.getValues("type");

    if (
      vin &&
      type === PlateVinFormType.Vin &&
      router.query.vin &&
      !hasSubmittedRef.current
    ) {
      setIsLoading(true);
      handleSubmit();
      hasSubmittedRef.current = true;
    }
  }, [form.watch("vin"), form.watch("type"), router.query.vin]);

  return (
    <>
      {data.CoBrandedName && (
        <div
          className={`${parseBgColor(data.Background)} flex ${
            isMobileView ? "flex-col" : "justify-center"
          } relative items-center pt-5 mx-3 max-w-full`}
        >
          {!isMobileView && (
            <div className="text-[#7C7C7C] text-[12px] font-[400] ml-2.5">
              Referred by
            </div>
          )}
          {isMobileView && (
            <div className="text-[#7C7C7C] text-[12px] font-[400] mb-2">
              Referred by
            </div>
          )}
          <div
            className={`${
              isMobileView ? "hidden" : "block"
            } bg-[#D7D7D7] w-[1px] max-h-[50px] min-h-[20px] mx-2.5`}
          ></div>

          <div className="w-full max-w-[450px]">
            <Image
              className="w-full h-auto object-contain"
              src={data?.CoBrandedImage?.data.attributes.url}
              alt={`${data.CoBrandedName} logo`}
              width={800}
              height={300}
            />
          </div>
        </div>
      )}

      <div className="text-center font-600 text-[36px] my-4 mx-4">
        {data.Title}
      </div>
      <div
        className={`w-[90%] xs:w-[384px] ${parseBgColor(
          data.Background
        )} border border-[#C3C3C3]  px-8 xs:pl-[58px] xs:pr-[69px] pt-[44px] pb-[41px] mb-8  rounded-lg mx-auto my-8`}
      >
        <div className="w-full flex text-sm text-[#354360] text-center cursor-pointer">
          <div
            className={`w-1/2 ${
              !isVin ? "font-bold border-b-4" : "font-medium border-b-2"
            } border border-[#354360] border-t-0 border-l-0 border-r-0 pb-4`}
            onClick={() => {
              changeType(PlateVinFormType.Plate);
            }}
          >
            License plate
          </div>
          <div
            className={`w-1/2 ${
              isVin ? "font-bold border-b-4" : "font-medium border-b-2"
            } border border-[#354360] border-t-0 border-l-0 border-r-0 pb-4`}
            onClick={() => {
              changeType(PlateVinFormType.Vin);
            }}
          >
            VIN
          </div>
        </div>
        {isVin ? (
          <div className="w-full">
            <div className="w-full flex space-x-2 mt-8">
              <div className="w-1/2 relative flex justify-center">
                <button
                  className="w-full bg-white flex items-center justify-between text-base text-white font-normal py-[6px] px-3 border focus:border-2 border-[#9797aa] border-solid focus:outline focus:border-[#00a0c7] focus:outline-[#bec3cd] focus:outline-4 transition-all rounded"
                  onClick={() => {
                    showVehicleModal();
                  }}
                  ref={btnRef1}
                >
                  <span className="text-base text-[#333] font-medium">
                    {vehicleType}
                  </span>
                  <Image
                    width={10}
                    height={6}
                    src="/assets/expand_icon.svg"
                    alt="null"
                  />
                </button>
                {isVehicleModalOnSellEntry && (
                  <VehicleTypeModalOnSellSentry
                    isVehicleModalOnSellEntry={isVehicleModalOnSellEntry}
                    setIsVehicleModalOnSellEntry={setIsVehicleModalOnSellEntry}
                    setVehicleType={changeVType}
                    btnRef={btnRef1}
                    designType={data.Design}
                  />
                )}
              </div>
              <div className="w-1/2 relative">
                <button
                  className={cn(
                    "w-full bg-white flex items-center justify-between text-base text-white font-normal py-[6px] px-3 border focus:border-2 border-[#9797aa] border-solid focus:outline focus:border-[#00a0c7] focus:outline-[#bec3cd] focus:outline-4 transition-all rounded",
                    { "border-[#d80808]": Boolean(errors.state) }
                  )}
                  onClick={() => showStateModal()}
                  ref={btnRef4}
                >
                  <span className="text-base text-[#212529] font-medium truncate">
                    {selectedState == "" ? "State" : selectedState}
                  </span>
                  <Image
                    width={10}
                    height={6}
                    src="/assets/expand_icon.svg"
                    alt="null"
                  />
                </button>
                {isStateModalOpen && (
                  <StateListModal
                    isStateModalOpened={isStateModalOpen}
                    setIsStateModalOpened={showStateModal}
                    setState={changeState}
                    btnRef={btnRef4}
                  />
                )}
              </div>
            </div>
            <div className="flex justify-center mt-4">
              <div className="relative w-full z-0">
                <input
                  type="text"
                  className={`form-control block w-full px-3 py-[6px] text-base font-medium bg-white border border-solid ${
                    isBadReq || Boolean(errors.vin) || isVinShort
                      ? "border-[#d80808] focus:border-[#d80808]"
                      : "border-[#9797aa] focus:outline focus:outline-[#cbced4] focus:border-black"
                  } rounded m-0 focus:outline-4 transition-all`}
                  placeholder="Enter VIN..."
                  {...register("vin")}
                />
                {(isVinShort || isBadReq || Boolean(errors.vin)) && (
                  <Image
                    width={12}
                    height={12}
                    className="absolute top-[11px] right-[10px] w-[20px] z-40"
                    src="/assets/invalid.svg"
                    alt="invalid"
                  />
                )}
              </div>
            </div>

            <div className="w-full flex justify-center">
              <button
                className="w-[177px] bg-[#00B3DE] hover:opacity-80 border border-[#00A0C7] text-white text-base font-bold py-2 px-4 rounded cursor-pointer mt-4"
                onClick={handleSubmit}
              >
                Get started
              </button>
            </div>
            <div className="w-full text-xs font-medium text-[#808080] text-center mt-4">
              A valid VIN or License plate is required to list your car on
              PrivateAuto.&nbsp;
              {!isNative ? (
                <Link
                  href="/blog/where-is-my-vin-number "
                  className="underline text-[#00B3DE]"
                >
                  Learn more
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          <div className="w-full">
            <div className="w-full flex space-x-2 mt-8">
              <div className="w-1/2 relative flex justify-center">
                <button
                  className="w-full bg-white flex items-center justify-between text-base text-white font-normal py-[6px] px-3 border focus:border-2 border-[#9797aa] border-solid focus:outline focus:border-[#00a0c7] focus:outline-[#bec3cd] focus:outline-4 transition-all rounded"
                  onClick={() => {
                    showVehicleModal();
                  }}
                  ref={btnRef2}
                >
                  <span className="text-base text-[#333] font-medium">
                    {vehicleType}
                  </span>
                  <Image
                    width={10}
                    height={6}
                    src="/assets/expand_icon.svg"
                    alt="null"
                  />
                </button>
                {isVehicleModalOnSellEntry && (
                  <VehicleTypeModalOnSellSentry
                    isVehicleModalOnSellEntry={isVehicleModalOnSellEntry}
                    setIsVehicleModalOnSellEntry={setIsVehicleModalOnSellEntry}
                    setVehicleType={changeVType}
                    btnRef={btnRef2}
                    designType={data.Design}
                  />
                )}
              </div>
              <div className="w-1/2 relative">
                <button
                  className={`w-full bg-white flex items-center justify-between text-base text-white font-normal py-[6px] px-3 border focus:border-2 ${
                    Boolean(errors.state)
                      ? "border-[#d80808]"
                      : "border-[#9797aa]"
                  } border-solid focus:outline focus:border-[#00a0c7] focus:outline-[#bec3cd] focus:outline-4 transition-all rounded`}
                  onClick={() => showStateModal()}
                  ref={btnRef3}
                >
                  <span className="text-base text-[#212529] font-medium truncate">
                    {selectedState == "" ? "State" : selectedState}
                  </span>
                  <Image
                    width={10}
                    height={6}
                    src="/assets/expand_icon.svg"
                    alt="null"
                  />
                </button>
                {isStateModalOpen && (
                  <StateListModal
                    isStateModalOpened={isStateModalOpen}
                    setIsStateModalOpened={showStateModal}
                    setState={changeState}
                    btnRef={btnRef3}
                  />
                )}
              </div>
            </div>
            <div className="flex justify-center mt-4">
              <div className="relative w-full z-0">
                <input
                  type="text"
                  className={`form-control block w-full px-3 py-[6px] text-base font-medium bg-white border border-solid ${
                    isBadReq || Boolean(errors.plate)
                      ? "border-[#d80808] focus:border-[#d80808]"
                      : "border-[#9797aa] focus:outline focus:outline-[#cbced4] focus:border-black"
                  } rounded m-0 focus:outline-4 transition-all`}
                  placeholder="Enter License plate"
                  {...register("plate")}
                />
              </div>
            </div>
            <div className="w-full flex justify-center">
              <button
                className="w-[177px] bg-[#00B3DE] hover:opacity-80 border border-[#00A0C7] text-white text-base font-bold py-2 px-4 rounded cursor-pointer mt-4"
                onClick={handleSubmit}
              >
                Get started
              </button>
            </div>
            <div className="w-full text-xs font-medium text-[#808080] text-center mt-4">
              A valid VIN or License plate is required to list your car on
              PrivateAuto.&nbsp;
              {!isNative ? (
                <Link
                  href="/blog/where-is-my-vin-number "
                  className="underline text-[#00B3DE]"
                >
                  Learn more
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
        )}

        {openVintageVehicleModal && (
          <VintageEntryModal
            openVintageVehicleModal={openVintageVehicleModal}
            setOpenVintageVehicleModal={setIsOpenVintageVehicleModal}
            btnRef={btnRef}
            vin={currentVin ?? ""}
            state={selectedState}
          />
        )}
      </div>
    </>
  );
};

export default Design2VinEntry;
