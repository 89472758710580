import { useState, useEffect } from "react";
import Image from "next/image";
import { MoreFilter } from "@/interfaces/MoreFilter";
import { moreFilterFieldArr } from "@/libs/constants";
import { getAttributeFromFilterField } from "@/libs/utils";

type Props = {
  moreFilterData: any;
  moreFiltersArr: any;
  setMoreFiltersArr: Function;
};

const MAX_LENGTH = 30;
const VIEW_LIMIT = 11;

const MoreFilterSection = ({
  moreFilterData,
  moreFiltersArr,
  setMoreFiltersArr,
}: Props) => {
  const [filterField, setFilterField] = useState<string>("");
  const [viewCount, setViewCount] = useState<number>(VIEW_LIMIT);

  const [exteriorColorArr, setExteriorColorArr] = useState<Array<string>>(
    moreFiltersArr.exteriorColor
  );
  const [interiorColorArr, setInteriorColorArr] = useState<Array<string>>(
    moreFiltersArr.interiorColor
  );
  const [fuelTypeArr, setFuelTypeArr] = useState<Array<string>>(
    moreFiltersArr.fuelType
  );
  const [transmissionArr, setTransmissionArr] = useState<Array<string>>(
    moreFiltersArr.transmission
  );
  const [driveTypeArr, setDriveTypeArr] = useState<Array<string>>(
    moreFiltersArr.driveType
  );
  const [cylindersArr, setCylindersArr] = useState<Array<string>>(
    moreFiltersArr.cylinders
  );

  const getResetArr = (arr: Array<string>, item: string) => {
    if (arr.includes(item)) {
      const index = arr.indexOf(item);
      if (index > -1) {
        arr.splice(index, 1);
      }
    } else {
      arr.push(item);
    }
    return arr;
  };

  const clickItem = (type: string, item: string) => {
    if (type == "exteriorColor") {
      const exteriors = exteriorColorArr.slice();
      setExteriorColorArr(getResetArr(exteriors, item));
    } else if (type == "interiorColor") {
      const interiors = interiorColorArr.slice();
      setInteriorColorArr(getResetArr(interiors, item));
    } else if (type == "fuel") {
      const fuels = fuelTypeArr.slice();
      setFuelTypeArr(getResetArr(fuels, item));
    } else if (type == "transmission") {
      const transmissions = transmissionArr.slice();
      setTransmissionArr(getResetArr(transmissions, item));
    } else if (type == "driveType") {
      const drives = driveTypeArr.slice();
      setDriveTypeArr(getResetArr(drives, item));
    } else {
      const cylinders = cylindersArr.slice();
      setCylindersArr(getResetArr(cylinders, item));
    }
  };

  const isChecked = (type: string, item: string): boolean => {
    if (type == "exteriorColor") {
      return exteriorColorArr.includes(item);
    } else if (type == "interiorColor") {
      return interiorColorArr.includes(item);
    } else if (type == "fuel") {
      return fuelTypeArr.includes(item);
    } else if (type == "transmission") {
      return transmissionArr.includes(item);
    } else if (type == "driveType") {
      return driveTypeArr.includes(item);
    } else {
      return cylindersArr.includes(item);
    }
  };

  const getItemCount = (type: string): number => {
    if (type == "exteriorColor") {
      return exteriorColorArr.length;
    } else if (type == "interiorColor") {
      return interiorColorArr.length;
    } else if (type == "fuel") {
      return fuelTypeArr.length;
    } else if (type == "transmission") {
      return transmissionArr.length;
    } else if (type == "driveType") {
      return driveTypeArr.length;
    } else {
      return cylindersArr.length;
    }
  };

  useEffect(() => {
    let data: MoreFilter = {
      exteriorColor: [],
      interiorColor: [],
      fuelType: [],
      transmission: [],
      driveType: [],
      cylinders: [],
    };
    data.exteriorColor = exteriorColorArr;
    data.interiorColor = interiorColorArr;
    data.fuelType = fuelTypeArr;
    data.transmission = transmissionArr;
    data.driveType = driveTypeArr;
    data.cylinders = cylindersArr;
    setMoreFiltersArr(data);
  }, [
    exteriorColorArr,
    interiorColorArr,
    fuelTypeArr,
    transmissionArr,
    driveTypeArr,
    cylindersArr,
  ]);

  return (
    <div className="text-base text-[#212529]">
      <div className="text-left text-lg text-[#333] font-semibold py-2">
        {moreFilterFieldArr.map((item: string, i: number) => {
          return (
            <div key={i}>
              <div
                className="flex justify-between items-center py-2 cursor-pointer"
                onClick={() => {
                  setViewCount(VIEW_LIMIT);
                  if (filterField == item) {
                    setFilterField("");
                  } else {
                    setFilterField(item);
                  }
                }}
              >
                <p>
                  {item}
                  {getItemCount(getAttributeFromFilterField(item)) != 0 && (
                    <span className="text-[#00b3de]">
                      ({getItemCount(getAttributeFromFilterField(item))})
                    </span>
                  )}
                </p>
                <Image
                  width={15}
                  height={10}
                  className={(filterField == item && "rotate-180") || ""}
                  src="/assets/expand_icon.svg"
                  alt="null"
                />
              </div>
              {filterField == item &&
                moreFilterData[getAttributeFromFilterField(item)]
                  .sort((a: any, b: any) => {
                    const aMatch = a.match(/^(\d+)/);
                    const bMatch = b.match(/^(\d+)/);
                    return aMatch && bMatch
                      ? a.match(/^(\d+)/)[1] - b.match(/^(\d+)/)[1]
                      : a.localeCompare(b);
                  })
                  .map((filterItem: string, index: number) => {
                    if (index < viewCount) {
                      return (
                        <div
                          className="w-full flex items-center space-x-2 text-base font-medium text-[#212529] py-2 cursor-pointer"
                          key={index}
                          onClick={() =>
                            clickItem(
                              getAttributeFromFilterField(item),
                              filterItem
                            )
                          }
                        >
                          {isChecked(
                            getAttributeFromFilterField(item),
                            filterItem
                          ) ? (
                            <Image
                              width={20}
                              height={20}
                              src="/assets/checkbox-checked.svg"
                              alt="checkbox-checked"
                            />
                          ) : (
                            <Image
                              width={20}
                              height={20}
                              src="/assets/checkbox-unchecked.svg"
                              alt="checkbox-unchecked"
                            />
                          )}
                          <div>
                            {filterItem.length > MAX_LENGTH
                              ? filterItem.slice(0, MAX_LENGTH) + "..."
                              : filterItem}
                          </div>
                        </div>
                      );
                    }
                    if (index == 15 && viewCount != Number.MAX_SAFE_INTEGER) {
                      return (
                        <div className="py-2" key={index}>
                          <button
                            className="bg-white text-sm text-[#00b3de] font-semibold py-2 px-4 border-2 border-[#00b3de] rounded"
                            onClick={() =>
                              setViewCount(Number.MAX_SAFE_INTEGER)
                            }
                          >
                            View more
                          </button>
                        </div>
                      );
                    }
                  })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MoreFilterSection;
