import Image from "next/image";
import EllipsisIcon from "@/components/Icon/EllipsisIcon";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";

type Props = {
  pages: number;
  currentPage: number;
  total: number;
  rows: number;
  onClick: Function;
  pageSize: number;
};

const Pagination = ({
  pages,
  currentPage,
  total,
  rows,
  onClick,
  pageSize,
}: Props) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const pageNumbers = [];
  let startPage: number = 1;
  let endPage: number = 1;

  if (
    currentPage == 0 ||
    currentPage == 1 ||
    currentPage == 2 ||
    currentPage == 3
  ) {
    startPage = 1;
    endPage = Math.min(pages, 5);
  } else if (currentPage < pages - 3) {
    startPage = Math.max(1, currentPage);
    endPage = Math.min(pages, currentPage + 2);
  } else {
    startPage = Math.max(1, pages - 3);
    endPage = pages;
  }

  for (let i = startPage; i <= endPage; i++) {
    if (i != 1) {
      pageNumbers.push(i);
    }
  }

  const totalPages = Math.ceil(total / pageSize);

  const ellipsisStart = startPage > 2;
  const ellipsisEnd = endPage < totalPages - 1;

  return (
    <div className="w-full bg-[#f8f8f8] py-16 text-center">
      <nav className="flex justify-center items-center pb-3">
        <ul className="flex list-none">
          {currentPage > 0 ? (
            <li className="flex items-center md:px-2">
              <FaChevronLeft
                className="w-6 h-6 text-black cursor-pointer"
                onClick={() => {
                  onClick(currentPage - 1);
                  scrollToTop();
                }}
              />
            </li>
          ) : (
            <li className="flex items-center md:px-2">
              <FaChevronLeft className="w-6 h-6 text-[#C4C4C4] cursor-not-allowed" />
            </li>
          )}
          <li className="mx-[3px] flex justify-center items-center">
            <a
              className={`px-2 py-2 cursor-pointer w-[32px] h-[32px] flex justify-center items-center ${
                currentPage === 0
                  ? "bg-[#2e3b54] text-white"
                  : "hover:bg-[#2e3b541c]"
              } rounded-full`}
              onClick={() => {
                onClick(0);
                scrollToTop();
              }}
            >
              1
            </a>
          </li>
          {ellipsisStart && (
            <li className="mx-[3px] flex justify-center items-center ">
              <EllipsisIcon />
            </li>
          )}
          {pageNumbers.map((page) => (
            <li
              key={page}
              className="mx-[6px] flex justify-center items-center "
            >
              <a
                className={`px-2 py-2 w-[32px] h-[32px] cursor-pointer flex justify-center items-center ${
                  currentPage == page - 1
                    ? "bg-[#2e3b54] text-white"
                    : "hover:bg-[#2e3b541c]"
                } rounded-full`}
                onClick={() => {
                  onClick(page - 1);
                  scrollToTop();
                }}
              >
                {page}
              </a>
            </li>
          ))}
          {ellipsisEnd && (
            <li className="mx-[3px] flex justify-center items-center ">
              <EllipsisIcon />
            </li>
          )}
          <li className="mx-[3px]">
            <span
              className={`px-3 py-2 flex justify-center items-center ${
                currentPage === pages
                  ? "w-[32px] h-[32px] bg-[#2e3b54] text-white"
                  : ""
              } rounded-full`}
            >
              {pages + 1}
            </span>
          </li>
          {currentPage < totalPages - 1 ? (
            <li className="flex items-center md:px-2">
              <FaChevronRight
                className="w-6 h-6 cursor-pointer text-black"
                onClick={() => {
                  onClick(currentPage + 1);
                  scrollToTop();
                }}
              />
            </li>
          ) : (
            <li className="flex items-center md:px-2">
              <FaChevronRight className="w-6 h-6 cursor-not-allowed text-[#C4C4C4]" />
            </li>
          )}
        </ul>
      </nav>
      {currentPage * pageSize + 1} -{" "}
      {currentPage * pageSize +
        (currentPage === pages ? total % pageSize : pageSize)}
    </div>
  );
};

export default Pagination;
