import { useState } from "react";
import Image from "next/image";
import { Tabs, TabPanel, Tab, TabList } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import cx from "classnames";
import VerifiedIcon from "@/components/Icon/VerifiedIcon";
import { useAuthValue } from "@/contexts/contextAuth";
import { checkHomePage } from "@/libs/utils";

const tabClasses = {
  default: "cursor-pointer",
  selected: "font-bold border-b-2 border-[#354360]",
};

const DealStartNowSection = () => {
  const { setIsSignup, setIsDealNowDealStart } = useAuthValue();
  const [isBuyer, setIsBuyer] = useState<boolean>(true);

  const createDealNowLink = () => {
    const customerType = isBuyer ? "BUYER" : "SELLER";
    window?.dataLayer &&
      window.dataLayer.push({
        event: "Deal Now",
        deal_type: customerType,
      });

    window?.fbq &&
      window.fbq("track", "Deal Now", {
        deal_type: customerType,
      });

    setIsSignup(true);
    setIsDealNowDealStart(customerType.toLowerCase());
  };

  return (
    <div className="relative w-full h-full bg-[#3f4c4e] bg-opacity-100">
      <Image
        width={1006}
        height={500}
        src="/assets/deal-start-img.png"
        className="w-full min-h-[466px] sm:min-h-[500px] max-h-[576px] opacity-40 object-cover hidden sm:block"
        alt="start DealNow"
      />
      <Image
        width={375}
        height={466}
        src="/assets/start-dealnow-mobile.png"
        className="w-full h-[466px] opacity-40 object-cover block sm:hidden"
        alt="start DealNow"
      />
      <div className="absolute left-0 top-0 w-full h-full block sm:flex justify-center items-start pt-10 md:pt-[83px] pb-[79px] vs:px-10 md:pl-[151px] md:pr-[132px] space-y-10 sm:space-y-0 space-x-0 md:space-x-[118px]">
        <div className="w-[269px] sm:w-[415px] text-white pt-4 mx-auto sm:mx-0">
          <h2 className="text-[34px] sm:text-[50px] font-bold">
            Start a {checkHomePage() ? "Deal" : "DealNow"}
          </h2>
          <p className="text-base sm:text-xl">
            Already have a buyer or seller? Use our DealNow feature to easily
            send a deal link to the other party to close the sale.
          </p>
          <div className="text-base sm:text-xl font-semibold space-y-3 pt-4">
            <div className="flex space-x-2 items-center">
              <VerifiedIcon color="#4DCBAB" bordercolor="#4DCBAB" />
              <p>Start a deal</p>
            </div>
            <div className="flex space-x-2 items-center">
              <VerifiedIcon color="#4DCBAB" bordercolor="#4DCBAB" />
              <p>Send invite link</p>
            </div>
            <div className="flex space-x-2 items-center">
              <VerifiedIcon color="#4DCBAB" bordercolor="#4DCBAB" />
              <p>Close the sale</p>
            </div>
          </div>
        </div>
        <div className="w-full sm:w-auto flex justify-center">
          <div className="w-[90%] xs:w-[384px] bg-white border border-[#354360] px-8 xs:pl-[58px] xs:pr-[69px] pt-[44px] pb-[41px] rounded-lg">
            <Tabs>
              <TabList className="w-full flex border-b-2 border-[#354360] text-sm text-[#354360] list-none">
                <Tab
                  className={cx(tabClasses.default, "w-1/2 pb-3 text-center")}
                  selectedClassName={cx(
                    tabClasses.selected,
                    "border-t-0 border-r-0 border-l-0 pb-3"
                  )}
                  onClick={() => setIsBuyer(true)}
                >
                  Buyer
                </Tab>
                <Tab
                  className={cx(tabClasses.default, "w-1/2 pb-2 text-center")}
                  selectedClassName={cx(
                    tabClasses.selected,
                    "border-t-0 border-r-0 border-l-0 pb-2"
                  )}
                  onClick={() => setIsBuyer(false)}
                >
                  Seller
                </Tab>
              </TabList>
              <div className="border-b border-[#354360]"></div>
              <TabPanel className="pt-8">
                <div className="w-full">
                  <div className="w-full h-[100px] flex justify-center mt-8">
                    <Image
                      width={83}
                      height={100}
                      src="/assets/buyer-deal.svg"
                      alt="buyer deal"
                      loading="lazy"
                    />
                  </div>
                  <div className="w-full text-base text-center pt-4">
                    <p className="text-[#2E3B54] font-bold">
                      I want to buy a vehicle
                    </p>
                    <p className="text-[#8A8888] font-normal">
                      Send a deal link to purchase the sellers vehicle
                    </p>
                  </div>
                  <button
                    className="w-full bg-[#00B3DE] hover:opacity-80 border border-[#00A0C7] text-white text-base font-bold py-2 px-4 rounded cursor-pointer mt-6"
                    onClick={() => createDealNowLink()}
                  >
                    Create a DealNow link
                  </button>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="w-full">
                  <div className="w-full h-[100px] flex justify-center mt-8">
                    <Image
                      width={158}
                      height={98}
                      src="/assets/seller-deal.svg"
                      alt="seller deal"
                      loading="lazy"
                    />
                  </div>
                  <div className="w-full text-base text-center pt-4">
                    <p className="text-[#2E3B54] font-bold">
                      I have a buyer for my vehicle
                    </p>
                    <p className="text-[#8A8888] font-normal">
                      Send a deal link to the buyer to purchase your vehicle
                    </p>
                  </div>
                  <button
                    className="w-full bg-[#00B3DE] hover:opacity-80 border border-[#00A0C7] text-white text-base font-bold py-2 px-4 rounded cursor-pointer mt-6"
                    onClick={() => createDealNowLink()}
                  >
                    Create a DealNow link
                  </button>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealStartNowSection;
