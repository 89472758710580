import { useState } from "react";
import Link from "next/link";
import { useAuthValue } from "@/contexts/contextAuth";
import {
  parseListsTitle,
  parseListsColor,
  checkNativePage,
  checkSellPage,
  scrollToTop,
} from "@/libs/utils";

type Props = {
  data: any;
  notPricingPage?: boolean;
};

const Lists = ({ data, notPricingPage }: Props) => {
  const [isPayNow, setIsPayNow] = useState<Boolean>(true);
  const { setIsSignup } = useAuthValue();

  const isNative = checkNativePage();

  const isSellPage = checkSellPage();

  return (
    <div className="m-w-[200px]">
      {data.Title == "Closing fee" && (
        <div className="flex justify-center w-[330px] h-[38px] mt-5">
          <div className=" w-full flex justify-center items-center bg-[#DBF4FA] text-base text-[#354360] font-bold px-0.5 relative rounded-t-lg ">
            Only pay if your car sells
          </div>
        </div>
      )}
      <div className={`flex justify-start pb-2`}>
        <div
          className={`w-[330px]   ${
            notPricingPage ? "h-[514px]" : "h-[464px]"
          } ${parseListsColor(data.Color)} border-2 border-[#E6E6E6] ${
            data.Title == "Closing fee" ? "rounded-b-lg" : "rounded-lg"
          }`}
        >
          <div
            className={`${parseListsTitle(data.TitleStyle)} text-left ${
              data.Color == "White" ? "text-[#354360]" : "text-[#fff]"
            }   ${
              notPricingPage
                ? "h-[88px] bg-[#2E313B] w-full rounded-t-lg text-white flex items-center text-[24px] justify-center"
                : "ml-5 pt-3"
            }`}
          >
            {data.Title}
          </div>
          <div className="text-center ml-5 py-2">
            <div className="flex justify-start items-center space-x-4">
              <div
                className={`text-[56px] font-extrabold sm:leading-[3rem] md:leading-[4rem] text-[#354360]`}
              >
                {data.Title == "Buyer" || isPayNow ? data.SubTitle : "$150"}
              </div>
            </div>
            <div className="text-[13px] text-[#354360] text-start pr-2">
              {data.Content}
            </div>
          </div>
          <hr className="mx-5 text-[#EFF0F6]" />
          <div className="pl-5 py-4">
            <div className="text-[700]">Includes:</div>

            <ul className="list-disc pl-5">
              {data.Items.map((item: any, index: number) => (
                <li
                  key={index}
                  className=""
                  style={{
                    display:
                      item.IconPosition != "Right" ? "list-item" : "none",
                  }}
                >
                  <div className={`text-sm text-[#354360] font-normal`}>
                    {item.Leader != null && (
                      <span className="font-bold">
                        {item.Leader}&nbsp;&nbsp;
                      </span>
                    )}
                    {item.Item}
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex justify-center">
            {data.Title == "Listing fee" ? (
              <div className="w-full px-5">
                <button
                  onClick={() => {
                    if (isSellPage) {
                      scrollToTop();
                    } else {
                      const sellPageUrl = `${isNative ? "/native" : ""}/sell`;
                      window.location.href = sellPageUrl;
                    }
                  }}
                  className=" bg-[#00b3de] text-base w-full text-white font-normal py-2 px-4 border border-[#00a0c7] border-solid rounded"
                >
                  Sell a vehicle
                </button>
              </div>
            ) : data.Title == "Closing fee" ? (
              <div className="text-[13px] font-medium">
                Skip the listing and do the{" "}
                <Link href={"/dealnow"} className="underline text-[#00B3DE]">
                  DealNow?
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lists;
