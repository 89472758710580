import { useEffect, useState } from "react";
import MultiRangeSlider from "@/components/multiRangeSlider/multiRangeSlider";
import { MIN_YEAR, MAX_YEAR } from "@/libs/constants";
import { IMaskInput } from "react-imask";

type Props = {
  parentMinYear: number;
  setParentMinYear: Function;
  parentMaxYear: number;
  setParentMaxYear: Function;
};

const YearSection = ({
  parentMinYear,
  setParentMinYear,
  parentMaxYear,
  setParentMaxYear,
}: Props) => {
  const [minYearInput, setMinYearInput] = useState<string>("");
  const [maxYearInput, setMaxYearInput] = useState<string>("");

  useEffect(() => {
    setMinYearInput(`${parentMinYear}`);
    setMaxYearInput(`${parentMaxYear}`);
  }, [parentMinYear, parentMaxYear]);

  useEffect(() => {
    setParentMinYear(Number(minYearInput));
    setParentMaxYear(Number(maxYearInput));
  }, [minYearInput, maxYearInput]);

  return (
    <div className="text-base text-[#212529]">
      <div className="text-left text-lg text-[#333] font-semibold pb-4">
        Year
      </div>
      <div className="w-full grid grid-cols-2 gap-x-4 font-semibold pb-4">
        <div className="text-left">
          <label className="form-label inline-block mb-2 text-gray-700">
            Min Year
          </label>
          <IMaskInput
            mask="0000" // Mask for a 4-digit year
            radix="."
            placeholder="No min"
            unmask={true} // Work with unmasked value
            min={MIN_YEAR}
            max={MAX_YEAR}
            value={minYearInput}
            className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded focus:border-[#a3b0ca] focus:text-gray-700 focus:bg-white focus:outline focus:outline-[#cbced4] focus:outline-4 transition-all"
            onFocus={() => {
              if (minYearInput) {
                setMinYearInput("");
              }
            }}
            onAccept={(value, mask) => {
              setMinYearInput(value);
            }}
          />
        </div>
        <div className="text-left">
          <label className="form-label inline-block mb-2 text-gray-700">
            Max Year
          </label>
          <IMaskInput
            mask="0000" // Mask for a 4-digit year
            radix="."
            placeholder="No max"
            unmask={true} // Work with unmasked value
            min={MIN_YEAR}
            max={MAX_YEAR}
            value={maxYearInput}
            className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded focus:border-[#a3b0ca] focus:text-gray-700 focus:bg-white focus:outline focus:outline-[#cbced4] focus:outline-4 transition-all"
            onFocus={() => {
              if (maxYearInput) {
                setMaxYearInput("");
              }
            }}
            onAccept={(value, mask) => {
              setMaxYearInput(value);
            }}
          />
        </div>
      </div>
      <hr className="w-full my-4 border-t-1 border-[#E5E5E5]" />
    </div>
  );
};

export default YearSection;
